// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-bliv-annoncoer-js": () => import("./../../../src/pages/information/bliv-annoncoer.js" /* webpackChunkName: "component---src-pages-information-bliv-annoncoer-js" */),
  "component---src-pages-information-cookie-og-privatlivspolitik-js": () => import("./../../../src/pages/information/cookie-og-privatlivspolitik.js" /* webpackChunkName: "component---src-pages-information-cookie-og-privatlivspolitik-js" */),
  "component---src-pages-information-kontakt-js": () => import("./../../../src/pages/information/kontakt.js" /* webpackChunkName: "component---src-pages-information-kontakt-js" */),
  "component---src-pages-information-om-js": () => import("./../../../src/pages/information/om.js" /* webpackChunkName: "component---src-pages-information-om-js" */),
  "component---src-pages-information-rangering-af-hoteller-js": () => import("./../../../src/pages/information/rangering-af-hoteller.js" /* webpackChunkName: "component---src-pages-information-rangering-af-hoteller-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

